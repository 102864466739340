
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  onUnmounted
} from "vue";
import store from "../services/store";

export default defineComponent({
  props: {
    token: Boolean
  },

  emits: ["successfullogin"],

  setup(props, { emit }) {
    const user = reactive({
      first_name: "",
      last_name: "",
      username: "",
      phone: "",
      email: "",
      password: ""
    });

    const success = ref(false);
    const registering = ref(false);
    const customerMessage = ref("");
    const has_error = ref(false);
    const error = ref("");

    const errors = ref({
      phone: [],
      password: []
    });

    const status = computed(() => store.state.status);

    onMounted(() => {
      document.body.classList.remove("has-aside");
    });

    onUnmounted(() => {
      document.body.classList.add("has-aside");
    });

    function showRegisterForm() {
      registering.value = true;
    }

    function showLoginForm() {
      registering.value = false;
    }

    function loginUser() {
      has_error.value = false;

      store
        .dispatch("login", user)
        .then(res => {
          if (res.data.status == "error") {
            has_error.value = true;
            error.value = res.data.message;
          } else {
            emit("successfullogin");
          }
        })
        .catch(e => {
          has_error.value = true;
          error.value = e.message;
        });
    }

    function registerUser() {
      has_error.value = false;

      store
        .dispatch("register", user)
        .then(res => {
          if (res.data.status == "error") {
            has_error.value = true;
            error.value = res.data.message;
          } else {
            emit("successfullogin");
          }
        })
        .catch(e => {
          has_error.value = true;
          error.value = e.message;
        });
    }

    return {
      loginUser,
      registerUser,
      customerMessage,
      user,
      has_error,
      error,
      success,
      registering,
      showLoginForm,
      showRegisterForm,
      status
    };
  }
});
